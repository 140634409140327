@import "styles/shared";

.Projects {
  padding-bottom: $verticalDistance;
  text-align: center;

  @media all and (min-width: 992px) {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  @media all and (min-width: 1280px) {
    padding-bottom: $verticalDistance * 3;
  }
}
.ProjectItem {
  text-align: center;
  margin-bottom: 40px;

  .image {
    display: inline-block;
    width: 15em;
    height: 5em;
    margin-bottom: 0.5em;
  }
  .description {
    margin-bottom: 0.5em;
  }
  .viewProject {
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media all and (min-width: 992px) {
    margin: 0 15px 30px;
    max-width: 330px;
    flex-grow: 1;
    flex: 1 1 30%;

    .description {
      font-size: 18px;
      line-height: 28px;
    }
  }
  @media all and (min-width: 1280px) {
    .description {
      font-size: 20px;
      line-height: 30px;
    }
  }
}
