@import "styles/shared";

.Home {
}

.HomePromo__bg {
  background-color: $lightGray;
}
.HomePromo {
  position: relative;
  height: 535px;
  min-width: $minWidth;
  text-align: center;
  margin-bottom: $verticalDistance;

  @media all and (min-width: 376px) {
    height: 575px;
  }
  @media all and (min-width: 426px) {
    height: 650px;
  }
  @media all and (min-width: 520px) {
    height: 740px;
  }
  @media all and (min-width: 650px) {
    height: 820px;
  }
  // Tablets and horizontal landscape
  @media all and (min-width: 768px) {
    height: 700px;
    text-align: left;
  }
  @media all and (min-width: 769px) {
    height: 800px;
  }

  // Desktops
  @media all and (min-width: 992px) {
    height: 634px;
  }
  @media all and (min-width: 1280px) {
    height: 680px;
    margin-bottom: 100px;
  }
  @media all and (min-width: 1440px) {
    width: 1440px;
    height: 680px;
  }

  .Button {
    z-index: 1;
    position: relative;

    // Tablets and horizontal landscape
    @media all and (min-width: 768px) {
      margin-left: 50px;
    }
    @media all and (min-width: 1280px) {
      margin-left: 125px;
    }
  }
}
.HomePromoImage {
  display: block;
  min-width: 375px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;

  img {
    display: block;
  }

  @media all and (min-width: 1440px) {
    img {
      width: 1440px;
      height: 704px;
    }
  }
}
.HomePromo__slogan {
  font-family: "SpaceGrotesk-Bold", Arial, sans-serif;
  font-size: 30px;
  line-height: 33px;
  letter-spacing: 0.5px;
  padding-top: 25px;
  padding-bottom: 10px;
  z-index: 1;
  position: relative;

  em {
    color: $red;
    font-style: normal;
  }

  .sp {
    display: block;
  }

  // Tablets and horizontal landscape
  @media all and (min-width: 768px) {
    font-size: 56px;
    line-height: 64px;
    padding-bottom: 25px;
    margin-left: 13px;

    .sp {
      margin-left: 38px;
    }
  }

  @media all and (min-width: 1280px) {
    font-size: 64px;
    line-height: 70px;
    padding-top: 85px;
    padding-bottom: 40px;
    margin-left: 82px;

    .sp {
      margin-left: 43px;
    }
  }
}
.HomePromo__team {
  z-index: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 15px;

  // Tablets and horizontal landscape
  @media all and (min-width: 768px) {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 30px;
    margin-left: 50px;
    width: 340px;
  }

  @media all and (min-width: 1280px) {
    font-size: 26px;
    line-height: 34px;
    width: 670px;
    margin-left: 125px;
    padding-bottom: 45px;
  }
}
.HomePromo__head {
  z-index: 1;
  position: relative;
  font-size: 15px;
  line-height: 21px;
  width: 150px;
  text-align: left;
  padding-top: 40px;

  .arrow {
    display: block;
    position: absolute;
    bottom: -70px;
    left: 20px;
  }

  @media all and (min-width: 768px) {
    margin-left: 50px;
    padding-top: 25px;

    .arrow {
      display: none;
    }
  }

  @media all and (min-width: 1280px) {
    margin-left: 125px;
    padding-top: 100px;
    font-size: 20px;
    line-height: 26px;
    width: 200px;
  }
}
.WhatWeCanDo {
  padding-bottom: $verticalDistance;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;

  @media all and (min-width: 1280px) {
    .Headline {
      padding-bottom: 80px;
    }

    padding-bottom: $verticalDistance * 3;
  }
}
.WhatWeCanDo__section {
  padding-bottom: 40px;
  text-align: initial;

  .contents {
    .caption {
      font-family: "SpaceGrotesk-Bold", Arial, sans-serif;
      font-size: 22px;
      line-height: 28px;
      padding-bottom: 15px;

      span {
        color: $blue;
      }
    }
    .text {
    }
  }
  .picture {
    display: none;
  }

  &:last-child {
    padding-bottom: 0;
  }

  @media all and (min-width: 992px) {
    .contents {
      .caption {
        font-size: 30px;
        line-height: 38px;
      }
      .text {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  @media all and (min-width: 1280px) {
    $padding: 100px;
    display: flex;
    padding-bottom: 0;

    .contents {
      padding-right: $padding;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .caption {
        font-family: "SpaceGrotesk-SemiBold", Arial, sans-serif;
        font-size: 35px;
        line-height: 41px;
      }
      .text {
        font-size: 22px;
        line-height: 30px;
      }
    }
    .picture {
      display: block;
    }

    &.left {
      flex-direction: row-reverse;

      .contents {
        padding-left: $padding;
        padding-right: 0;
      }
    }
  }
}
.WhatWeCanDo__curve {
  display: none;

  @media all and (min-width: 1280px) {
    display: inline-block;
  }
}
.Skills {
  max-width: 992px;
  margin: 0 auto;
  padding-bottom: $verticalDistance;
  text-align: center;

  @media all and (min-width: 992px) {
    max-width: initial;
  }
  @media all and (min-width: 1280px) {
    padding-bottom: $verticalDistance * 3;
  }
}
.Skills__line {
  $marginStep: 12px;

  .item {
    margin-bottom: 15px;
    font-size: 18px;
    span {
      color: $red;
    }
  }

  @media all and (min-width: 992px) {
    display: flex;
    justify-content: center;

    .item {
      font-size: 20px;
      padding: 20px 25px;
      background-color: $lightGray;
      border-radius: 40px;
      margin: 0 $marginStep $marginStep * 2 $marginStep;
    }
  }
  @media all and (min-width: 1280px) {
    .item {
      font-size: 22px;
      padding: 25px 30px;
    }
  }
}

.Technologies {
  padding-bottom: $verticalDistance;

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media all and (min-width: 992px) {
    }
  }

  @media all and (min-width: 1280px) {
    padding-bottom: $verticalDistance * 3;
  }
}

.Technologies__item {
  text-align: center;
  margin-bottom: 15px;

  .logo {
    width: 100%;
    //max-width: 300px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media all and (min-width: 992px) {
    .logo {
      margin-bottom: 20px;
    }
    .caption {
      font-size: 20px;
    }
  }
  @media all and (min-width: 1280px) {
    width: 234px;
  }
}

.HomeProjects {
}
