.Headline {
  font-family: "SpaceGrotesk-Bold", Arial, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: normal;
  letter-spacing: 0.5px;
  padding-bottom: 30px;
  text-transform: uppercase;
  text-align: center;

  @media all and (min-width: 992px) {
    font-family: "SpaceGrotesk-SemiBold", Arial, sans-serif;
    font-size: 40px;
    line-height: 48px;
    padding-bottom: 45px;
  }

  @media all and (min-width: 1280px) {
    font-family: "SpaceGrotesk-SemiBold", Arial, sans-serif;
    font-size: 46px;
    line-height: 52px;
    padding-bottom: 60px;
  }
}
