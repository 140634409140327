@import "styles/shared";

html {
  min-width: $minWidth;
}
body {
  font-size: 16px;
  line-height: 24px;
  font-family: "SpaceGrotesk-Regular", Arial, sans-serif;
  min-width: $minWidth;
}
.anchor {
  height: 1px;
}
.Website {
}
.PageNotFound {
  padding: $verticalDistance 0;
  text-align: center;
  background-color: $lightGray;
}
