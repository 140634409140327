@import "styles/shared";

.HeaderMenu {
  outline: none;
  padding: 80px 40px 0;
  background-color: $almostBlack !important;
  color: white !important;
  position: relative;

  @media all and (min-width: 992px) {
    left: initial !important;
    width: 300px;
  }

  .BurgerMenuIcon {
    position: absolute;
    top: 18px;
    right: $horizontalPadding;

    @media all and (min-width: 1280px) {
      top: 34px;
      right: $horizontalPadding * 2;
    }
  }
}

.HeaderMenu__navigation {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin: 0 0 20px;

    a {
      color: white;
      text-decoration: none;
      font-size: 22px;

      img {
        display: inline-block;
        vertical-align: -5px;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &.last {
      margin-top: 60px;
    }
  }
}
