@import "styles/shared";

footer {
  padding: $verticalDistance 0;
  background-color: $lightGray;
  position: relative;

  .code {
    display: none;

    @media all and (min-width: 992px) {
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      height: 78%;
    }
  }
}

.Footer {
  font-size: 18px;
  text-align: center;
  position: relative;
  z-index: 1;

  .Headline {
  }

  @media all and (min-width: 1280px) {
    font-size: 22px;
    line-height: 28px;
  }
}
.Footer__contents {
  p {
    margin: 0 0 5px;
  }
}
.Footer__head {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 30px;

  .image {
    display: block;
    width: 84px;
    margin-right: 20px;
    border-radius: 42px;
  }
  .text {
    text-align: left;

    p {
      margin: 0 0 5px;
    }
  }
}
.Footer__bottom {
  display: flex;
  flex-wrap: wrap;

  @media all and (min-width: 992px) {
    padding-top: 60px;
    justify-content: space-between;
  }
  @media all and (min-width: 1280px) {
    font-size: 18px;
  }
}
.Footer__address {
  padding-top: 30px;
  width: 100%;

  .first {
    margin-bottom: 20px;
  }

  @media all and (min-width: 992px) {
    width: auto;
    text-align: left;

    .first {
      margin-bottom: 5px;
    }
  }
}
.Footer__socials {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  a {
    display: inline-block;
    margin-left: 10px;

    img {
      display: block;
      height: 39px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @media all and (min-width: 992px) {
    width: auto;
  }
}
