@import "styles/shared";

.InternalPaddingWrapper {
  padding-left: $horizontalPadding;
  padding-right: $horizontalPadding;

  @media all and (min-width: 1280px) {
    padding-left: $horizontalPadding * 2;
    padding-right: $horizontalPadding * 2;
  }
}
