@font-face {
  font-family: "SpaceGrotesk-Bold";
  src: url("SpaceGrotesk-Bold.eot");
  src: url("SpaceGrotesk-Bold.eot?#iefix") format("embedded-opentype"),
    url("SpaceGrotesk-Bold.svg#SpaceGrotesk-Bold") format("svg"),
    url("SpaceGrotesk-Bold.ttf") format("truetype"),
    url("SpaceGrotesk-Bold.woff") format("woff"),
    url("SpaceGrotesk-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
