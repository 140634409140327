@import "styles/shared";

.BurgerMenuIcon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .icon {
    position: relative;
    width: 30px;
    height: 3px;
    top: -5px;
    background-color: $black;
    transition: transform 0.2s ease-in, top 0.2s linear;

    &::after {
      position: absolute;
      right: 0;
      top: 10px;
      content: "";
      width: 15px;
      height: 3px;
      background-color: $black;
      transition: transform 0.2s ease-in, top 0.2s linear, width 0.2s linear;
    }

    &.active {
      top: 0;
      transform: rotate(45deg);
      transition: transform 0.2s ease-in, top 0.2s linear;

      &::after {
        transform: rotate(-90deg);
        top: 0;
        width: 30px;
        transition: transform 0.2s ease-in, top 0.2s linear, width 0.2s linear;
      }
    }
  }

  &.white {
    .icon {
      background-color: white;

      &::after {
        background-color: white;
      }
    }
  }

  &:hover {
    .icon::after {
      width: 30px;
    }
  }
}
