@import "styles/shared";

.LetsTalkForm {
  outline: none;
  padding: 80px 40px 0;
  position: relative;

  @media all and (min-width: 992px) {
    left: initial !important;
    width: 300px;
  }

  .BurgerMenuIcon {
    position: absolute;
    top: 18px;
    right: $horizontalPadding;

    @media all and (min-width: 1280px) {
      top: 34px;
      right: $horizontalPadding * 2;
    }
  }
}

.LetsTalkForm__contents {
  .headline {
    font-family: "SpaceGrotesk-SemiBold", Arial, sans-serif;
    font-size: 35px;
    line-height: 41px;
    margin-bottom: 30px;

    span {
      color: $blue;
    }

    &.sent {
      padding-bottom: 20px;
      border-bottom: 1px solid #01a309;
      font-family: "SpaceGrotesk", Arial, sans-serif;
      font-size: 20px;
      line-height: 28px;
      margin-top: 20px;
    }
  }
  .error {
    padding: 15px 25px;
    color: $red;
    border: 1px solid $red;
    margin-top: 20px;
  }
}
.LetsTalkForm__form {
  input,
  textarea {
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 2px solid $lightGray;
    padding: 10px 0;
    margin-bottom: 20px;
    border-radius: 0;

    &:focus {
      border-color: $black;
    }
  }

  textarea {
    min-height: 100px;
    resize: vertical;
  }
}
