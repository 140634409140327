@import "styles/shared";

header {
  background-color: $lightGray;
}
.Header__contents {
  height: 70px;
  position: relative;
  z-index: 2;

  .BurgerMenuIcon {
    position: absolute;
    top: 18px;
    right: 0;

    @media all and (min-width: 1280px) {
      top: 34px;
    }
  }
}

.Header__logo {
  position: absolute;
  top: 24px;
  left: 0;

  img {
    display: block;
    width: 210px;
  }

  @media all and (min-width: 1280px) {
    top: 38px;
  }
}
