@import "styles/shared";

.Button {
  font-family: "SpaceGrotesk-SemiBold", Arial, sans-serif;
  font-size: 13px;
  width: 154px;
  height: 32px;
  background-color: $red;
  color: white;
  border: 2px solid $red;
  border-radius: 18px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  letter-spacing: 1px;
  font-weight: 500;
  cursor: pointer;

  .background {
    position: absolute;
    overflow: hidden;
    z-index: 0;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: white;
    border-radius: 50%;
    opacity: 0.9;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: 425ms cubic-bezier(0.5, 0, 0.07, 1);
    transition: 425ms cubic-bezier(0.5, 0, 0.07, 1);
  }
  .text {
    position: relative;
    z-index: 1;
    background-color: transparent;
    outline: none;
    border: none;
    color: inherit;
  }

  &:hover {
    color: $fontColor;
    -webkit-transition: color 550ms cubic-bezier(0.5, 0, 0.07, 1);
    transition: color 550ms cubic-bezier(0.5, 0, 0.07, 1);

    .background {
      width: 108%;
      height: 0;
      padding-top: 108%;
      opacity: 1;
      -webkit-transition: 550ms cubic-bezier(0.5, 0, 0.07, 1);
      transition: 550ms cubic-bezier(0.5, 0, 0.07, 1);
    }
  }

  &.safari {
    .background {
      transform: none;
      transition: none;
    }

    &:hover {
      transition: none;
      color: $fontColor;
      background-color: white;

      .background {
        transition: none;
        padding-top: 0;
      }
    }
  }

  @media all and (min-width: 992px) {
    font-size: 18px;
    height: 50px;
    border-radius: 26px;
    width: 200px;
  }

  @media all and (min-width: 1280px) {
    font-size: 20px;
    height: 60px;
    border-radius: 32px;
    width: 250px;
  }
}
