@import "colors";
@import "fonts/SpaceGrotesk-Regular/font.scss";
@import "fonts/SpaceGrotesk-Medium/font.scss";
@import "fonts/SpaceGrotesk-SemiBold/font.scss";
@import "fonts/SpaceGrotesk-Bold/font.scss";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-weight: normal;
  margin: 0;
}
