@font-face {
  font-family: "SpaceGrotesk-SemiBold";
  src: url("SpaceGrotesk-SemiBold.eot");
  src: url("SpaceGrotesk-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("SpaceGrotesk-SemiBold.svg#SpaceGrotesk-SemiBold") format("svg"),
    url("SpaceGrotesk-SemiBold.ttf") format("truetype"),
    url("SpaceGrotesk-SemiBold.woff") format("woff"),
    url("SpaceGrotesk-SemiBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
