@import "styles/shared";

.InlineLink {
  position: relative;
  text-decoration: none;
  display: inline-block;
  height: 25px;
  box-sizing: content-box;
  transition: color 0.3s;
  clear: both;

  .wrapper {
    display: inline-flex;
    overflow: hidden;
  }

  .text {
    text-decoration: none;
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
    color: $linkColor;
    height: 25px;

    &:after {
      width: 100%;
      transition: 0.6s;
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 0;
      height: 2px;
      background-color: $linkColor;
    }
  }

  &:before {
    width: 0%;
    transition: width 0.4s;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $linkColor;
    z-index: 0;
    height: 2px;
  }

  &:hover {
    &:before {
      width: 100%;
    }
    .text {
      &:after {
        width: 0;
        left: 100%;
        transition: 0.2s;
      }
    }
  }

  &.white {
    .text {
      color: white;

      &:after {
        background-color: white;
      }
    }

    &:before {
      background-color: white;
    }
  }
}
